<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>아이디어 접수 활성 상태</h3>
      </div>
      <div class="card mx-xl-5 mt-3 p-3 mb-5">
        <div class="card-body">
          <p v-if="isPlay" class="success">현재 활성화 상태 입니다.</p>
          <p v-else class="danger">현재 비활성화 상태 입니다.</p>
        </div>
      </div>
      <mdb-btn outline="success" small @click="ideaState(true)" icon="play">활성화 하기</mdb-btn>
      <mdb-btn outline="danger" small @click="ideaState(false)" icon="stop">비활성화 하기</mdb-btn>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbBtn, mdbCol, mdbContainer} from "mdbvue";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "IdeaBoardOnOff",
  components: {
    mdbContainer,
    mdbCol,
    mdbBtn,
  },
  data() {
    return {
      fbCollection: 'boardState',
      fbDoc: 'ideaBoard',
      isPlay: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getIdeaBoardState();
    },
    async getIdeaBoardState() {
      const self = this;
      const db = firebase.firestore();
      const result = await db.collection(self.fbCollection).doc(self.fbDoc).get()
      self.isPlay = result.data().isPlay;

    },
    ideaState(isPlay) {
      const self = this;
      const db = firebase.firestore();
      db.collection(self.fbCollection)
          .doc(self.fbDoc)
          .set({isPlay: isPlay}, {merge: true})
          .then(() => {
            self.isPlay = isPlay
          })
    }
  }
}
</script>

<style scoped>
.card-body p {
  margin: 0;
}

.success {
  color: green;
}

.danger {
  color: red;
}

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}
</style>